.wrapper-class {
    padding: 1rem;
    border: 1px solid #ffffff;
  }
  .editor-class {
    background-color:rgb(255, 255, 255);
    padding: 1rem;
    border: 1px solid #ccc;
    overflow-y: scroll;
    height: 300px;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  /* *{
    margin: 0;
    font-family: 'Inter', sans-serif !important;
  }
   */