.markdownBlog h2 *{
    font-size: 1.5rem  !important;
    font-weight: 600 !important;
    margin: 1.5rem 0 !important;
    color: #000 !important;
    background-color: transparent !important;
}
.markdownBlog p span {
    font-size: 18px  !important;
    font-weight: 400 !important;
    margin: 1.5rem 0 !important;
    color: #000 !important;
    background-color: transparent !important;
}
.markdownBlog p {
    font-size: 18px  !important;
    font-weight: 400 !important;
    margin: 1.5rem 0 !important;
    color: #000 !important;
    background-color: transparent !important;
}
.markdownBlog p:empty {
    height: 10px !important;
  }
.markdownBlog h3 *{
    font-size: 1.3rem  !important;
    font-weight: 600 !important;
    margin: 1.5rem 0 !important;
    color: #000 !important;
    background-color: transparent !important;
}
.markdownBlog h4 *{
    font-size: 1rem  !important;
    font-weight: 600 !important;
    margin: 1.5rem 0 !important;
    color: #000 !important;
    background-color: transparent !important;
}

@media only screen and (max-width: 1000px) {
    .markdownBlog h2 *{
        font-size: 1.1rem  !important;
        font-weight: 600 !important;
        margin: 1.5rem 0 !important;
        color: #000 !important;
        background-color: transparent !important;
    }
    .markdownBlog h3 *{
        font-size: 1rem  !important;
        font-weight: 600 !important;
        margin: 1.5rem 0 !important;
        color: #000 !important;
        background-color: transparent !important;
    }
    
    .markdownBlog p span {
        font-size: 16px  !important;
        font-weight: 300 !important;
        margin: 1.5rem 0 !important;
        color: #000 !important;
        background-color: transparent !important;
    }
    .markdownBlog p {
        font-size: 16px  !important;
        font-weight: 400 !important;
        line-height: 130%;
        margin: 1.5rem 0 !important;
        color: #000 !important;
        background-color: transparent !important;
    }

    .markdownBlog h4 *{
        font-size: .8rem  !important;
        font-weight: 500 !important;
        margin: 1.5rem 0 !important;
        color: #000 !important;
        background-color: transparent !important;
    }
}